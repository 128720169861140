import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';


import SEO from '../../components/SEO';
import Body from '../../components/layout/Body';
import ResponsiveContainer from '../../components/layout/ResponsiveContainer';
import NonStretchedImage from '../../components/layout/NonStretchedImage';
import RelativeWrapper from '../../components/layout/RelativeWrapper';

import { ViewportBreakpoints } from '../../components/cssConstants';

import FeatureListBreaker, { Features } from '../../components/cta/FeatureListBreaker';
import FeatureRow from '../../components/section/FeatureRow';

import QuotationMark from '../../components/frills/QuotationMark';
import SingleTestimonial from '../../components/section/SingleTestimonial';

import HeroH1 from '../../components/hero/HeroH1';
import HeroH2 from '../../components/hero/HeroH2';

import H2 from '@rotaready/frecl/build/H2';
import H4 from '@rotaready/frecl/build/H4';
import Text from '@rotaready/frecl/build/Text';
import Button from '@rotaready/frecl/build/Button';
import FlexContainer from '@rotaready/frecl/build/FlexContainer';
import UL from '@rotaready/frecl/build/UL';
import TextListItem from '@rotaready/frecl/build/TextListItem';

const DarkWrapper = styled.div`
  background-color: ${({ theme: { colors } }) => colors.brand180};
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 1440px 519px;
  background-image: url(${props => props.backgroundImage});
`;

const Hero = styled.div`
  padding: 80px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 120px 0;
  }
`;

const HeroInnerContainer = styled(FlexContainer)`
  flex-direction: column;
  width: 100%;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
    text-align: left;
  }
`;

const HeroBody = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    width: 45%;
    margin-right: 80px;
  }
`;

const HeroIllustration = styled.div`
  flex-grow: 1;

  .gatsby-image-wrapper {
    width: 100%;
    margin: 0 auto;
  }
`;

const CtaButtons = styled.div`
  margin: 30px auto;
  max-width: 180px;

  button {
    width: 100%;
    margin: 0 0 20px 0;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin-top: 40px;
    max-width: unset;

    button {
      width: unset;
      margin: 0 20px 0 0;
    }
  }
`;

const Subtitle = styled(Text)`
  margin: 20px 0 40px 0;
  font-size: 20px;
  line-height: 28px;
  color: ${({ theme: { colors } }) => colors.brand160};
`;

const header = {
  backgroundColor: 'brand180',
  invertColors: true,
};

const IntroSection = styled.div`
  text-align: center;
  padding: 20px 0 60px 0;

  > p {
    margin-bottom: 20px;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 20px 0 100px 0;
    width: 60%;
    margin: 0 auto;
  }
`;

const SectionTitle = styled(H2)`
  margin-bottom: 60px;
`;

const TestimonialWrapper = styled.div`
  overflow: hidden;
`;

const Page = ({ data }) => (
  <Body header={header}>
    <SEO
      title="HR Software & Employee Management | Rotaready"
      description="HR software that makes staff management a breeze with absence, holiday accrual, document storage & pay records seamlessly integrated with your rota."
      url="hr"
    />
    <DarkWrapper
      backgroundImage={data.heroBackgroundMask.childImageSharp.fixed.src}
      title="HR & Employee Management"
    >
      <ResponsiveContainer>
        <Hero>
          <HeroInnerContainer>
            <HeroBody>
              <HeroH1 color="brand" text="HR" />
              <HeroH2 color="white" text="Intelligent HR Platform for employee management" />

              <H4 uistyle="white">
                Discover a HR platform that goes hand-in-hand with your staff scheduling software for an employee management system. Our HR software includes employment records, 
                absence management, secure document storage, employee self-onboarding and more, whilst seamlessly integrating with the rest of Rotaready, 
                from <Link style="color: inherit;" to="/rota-scheduling">rotas</Link> and <Link style="color: inherit;" to="/time-attendance">time & attendance</Link>, 
                right through to <Link style="color: inherit;" to="/payroll">payroll</Link>.
              </H4>

              <CtaButtons>
                <Link to="/demo">
                  <Button uistyle="primary" text="Request demo" size="lg" />
                </Link>
                <Link to="/contact">
                  <Button uistyle="white" text="Speak to the team" size="lg" ghost borderless />
                </Link>
              </CtaButtons>
            </HeroBody>

            <HeroIllustration>
              <NonStretchedImage fluid={data.heroImage.childImageSharp.fluid} />
            </HeroIllustration>
          </HeroInnerContainer>
        </Hero>
      </ResponsiveContainer>
    </DarkWrapper>

   

    <RelativeWrapper backgroundColor="white">
      <ResponsiveContainer>
        <IntroSection>
          <SectionTitle uistyle="brand160" text="Why choose Rotaready's HR software?" />
          <Text size="xl">
            We make sure that busy managers within the hospitality, retail and leisure space are not bogged down with additional time-consuming management duties or stress. In fast paced industries such as these, having a confusing HR platform that takes hours to understand can cause more headaches than it is worth. This is the exact reason we’ve made our HR software extremely simple, clean and quick to use. Meanwhile, we’ve ensured it is tailored to the specific needs of people like you, hospitality, retail and leisure management professionals.
            <br />
            <br />
            Some of the capabilities of our HR platform include: 
          </Text>
        </IntroSection>
      </ResponsiveContainer>
    </RelativeWrapper>

    <FeatureRow
      mirrorArrange
      title="Full repository of employee information"
      illustrationImage={data.employeeRecordIllustration.childImageSharp.fluid}
      illustrationAltText="Employee record in Rotaready with personal information"
    >
      <Subtitle text="Keep track of your staff through their entire career with Rotaready's HR platform." />

      <UL size="xl" uistyle="normal" iconUistyle="primary">
        <TextListItem text="Full employment and job history records, across unlimited roles, locations and business areas" />
        <TextListItem text="Complete chronological pay rate history" />
        <TextListItem text="Secure storage of personal and financial information" />
        <TextListItem text="Easy access to emergency contact details" />
      </UL>
    </FeatureRow>

    <FeatureRow
      title="Manage holiday, absences & availability"
      illustrationImage={data.bookAbsenceIllustration.childImageSharp.fluid}
      illustrationAltText="Booking annual leave within Rotaready"
    >
      <Subtitle text="Effortlessly track every kind of absence: holiday, unpaid time off, annual leave, TOIL, sickness and more." />

      <UL size="xl" uistyle="normal" iconUistyle="primary">
        <TextListItem text="Elegant holiday booking process for the entire team" />
        <TextListItem text="Powerful review and approval controls for management" />
        <TextListItem text="Fully customisable paid & unpaid absence types" />
        <TextListItem text="Input employee availability patterns by day, week or month" />
        <TextListItem text="Annual holiday allowances in days or hours" />
      </UL>
    </FeatureRow>

    <FeatureRow
      mirrorArrange
      title="Automated holiday accrual"
      illustrationImage={data.absenceAllowancesIllustration.childImageSharp.fluid}
      illustrationAltText="Viewing a holiday allowance and the audit history for accrued hours"
    >
      <Subtitle text="Is calculating accrued holiday a headache? It doesn't have to be. You set the rules, we'll do the rest." />

      <UL size="xl" uistyle="normal" iconUistyle="primary">
        <TextListItem text="Automatic holiday accrual, calculated from hours worked" />
        <TextListItem text="Set rules for carrying over unused holiday" />
        <TextListItem text="Automatic calculation of average hours worked per day and week" />
        <TextListItem text="Ability to set accrual caps, overspend and custom holiday accrual rates" />
      </UL>
    </FeatureRow>

    <FeatureRow
      title="Document storage & e-signatures"
      illustrationImage={data.documentStorageIllustration.childImageSharp.fluid}
      illustrationAltText="Employee document storage within Rotaready"
    >
      <Subtitle text="Keep all important documentation in one place. With unlimited file storage at no extra cost." />

      <UL size="xl" uistyle="normal" iconUistyle="primary">
        <TextListItem text="Upload and associate documents with any employee" />
        <TextListItem text="Customise document categories to suit your business" />
        <TextListItem text="Send documents for legal e-signature, such as contracts" />
        <TextListItem text="Automatic alerts for time-sensitive documents that are due to expire" />
        <TextListItem text="Safeguards to ensure critical documents are provided for new starters" />
      </UL>
    </FeatureRow>

    <FeatureRow
      mirrorArrange
      title="Access any time, anywhere"
      illustrationImage={data.multipleDevicesIllustration.childImageSharp.fluid}
      illustrationAltText="Accessing sites and employee records through the Rotaready app"
    >
      <Subtitle>
        At work? On the move? Feet up in front of the TV? Sign-in to <Link to="/">Rotaready</Link> wherever you are.
      </Subtitle>

      <UL size="xl" uistyle="normal" iconUistyle="primary">
        <TextListItem text="Effortlessly manage multiple sites from one dashboard" />
        <TextListItem text="Individual log-ins for every employee" />
        <TextListItem text="Fully customisable permissions & access control" />
        <TextListItem text="Employee mobile app providing your teams with greater visibility" />
        <TextListItem text="Use on desktop, tablet or mobile with dedicated iPhone and Android apps" />
      </UL>
    </FeatureRow>

    <FeatureRow
      title="Guarantee compliance"
      illustrationImage={data.rotaComplianceIllustration.childImageSharp.fluid}
      illustrationAltText="Rotaready alerting an employee exceeding the working time regulations"
    >
      <Subtitle text="Be fully compliant with none of the stress." />

      <UL size="xl" uistyle="normal" iconUistyle="primary">
        <TextListItem text="Live rota validation to check staff hours are appropriately covered and skills/qualifications are respected" />
        <TextListItem text="Compliance with all the rules of the Working Time regulations" />
        <TextListItem text="Fully compliant with the GDPR and other Data Protection legislation" />
        <TextListItem text="Secure staff document storage with expiry-date alerts" />
      </UL>
    </FeatureRow>

    <TestimonialWrapper>
      <SingleTestimonial
        image={data.testimonialImage.childImageSharp.fluid}
        imageAlt="People at Topgolf"
      >
        <QuotationMark width={60} />

        <Text size="xl" uistyle="brand160">
          Rotaready's HR platform helps us save time and manage people processes more effectively, allowing us to focus on what really matters - our team
        </Text>

        <Text size="lg">
          Andrea Pitini, Project Manager at The Pizza Room
        </Text>
      </SingleTestimonial>
    </TestimonialWrapper>

    <FeatureListBreaker
      rows={1}
      excludeFeature={Features.HR}
      headingText="HR software alongside powerful features"
    />
    

    <RelativeWrapper backgroundColor="white">
      <ResponsiveContainer>
        <IntroSection>
          <SectionTitle uistyle="brand160" text="Join The Businesses Who Love Rotaready" />
          <Text size="xl">
            Are you ready to take a look at our powerful HR platform? Want to see how this could integrate with your business? We can talk about all your specific needs and show you how everything works. Management just got so much easier.
          </Text>
          <CtaButtons>
            <Link to="/demo">
              <Button uistyle="primary" text="Request demo" size="lg" />
            </Link>
            <Link to="/contact">
              <Button uistyle="brand160" text="Speak to the team" size="lg" ghost borderless />
            </Link>
          </CtaButtons>
        </IntroSection>
      </ResponsiveContainer>
    </RelativeWrapper>

  </Body>
);

export default Page;

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "misc/hero-hr.png" }) {
      childImageSharp {
        fluid(maxWidth: 518, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    heroBackgroundMask: file(relativePath: { eq: "misc/hero-mask-brand180.png" }) {
      childImageSharp {
        fixed(height: 519, width: 1440, quality: 100) {
          src
        }
      }
    }

    employeeRecordIllustration: file(relativePath: { eq: "illustrations/hr-employee-record.png" }) {
      childImageSharp {
        fluid(maxWidth: 509, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    bookAbsenceIllustration: file(relativePath: { eq: "illustrations/hr-absence-booking.png" }) {
      childImageSharp {
        fluid(maxWidth: 497, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    absenceAllowancesIllustration: file(relativePath: { eq: "illustrations/hr-absence-allowances.png" }) {
      childImageSharp {
        fluid(maxWidth: 534, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    documentStorageIllustration: file(relativePath: { eq: "illustrations/hr-document-storage.png" }) {
      childImageSharp {
        fluid(maxWidth: 484, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    multipleDevicesIllustration: file(relativePath: { eq: "illustrations/hr-multiple-devices.png" }) {
      childImageSharp {
        fluid(maxWidth: 490, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    rotaComplianceIllustration: file(relativePath: { eq: "illustrations/hr-rota-compliance.png" }) {
      childImageSharp {
        fluid(maxWidth: 457, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    testimonialImage: file(relativePath: { eq: "testimonial/feature-pizzarooms.png" }) {
      childImageSharp {
        fluid(maxWidth: 561, quality: 25) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }
  }
`;
